<div class="app">
  <div class="app__inner" [class.anim--in]="loading$ | async">
    <app-topbar
      (toggleOverlay)="toggleMenu()"
      (closeOverlay)="closeMenu()"
      (openOverlay)="openMenu()"
      [menuActive]="(menuOpen$ | async) ?? false"
      [menu]="('main-menu' | fromMenu)?.error ? [] : ('main-menu' | fromMenu)"
      *ngIf="loaded$ | async"
    ></app-topbar>

    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

    <app-footer [domain]="domainName" *ngIf="loaded$ | async"></app-footer>
  </div>
  <app-loader-indicator [loading]="(loading$ | async) ?? false"></app-loader-indicator>
  <fstr-cookie-notice
    [title]="'cookie-titel' | fromDictionary"
    *ngIf="loaded$ | async"
    [text]="'cookie-tekst' | fromDictionary"
    [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
    [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
  ></fstr-cookie-notice>
  <app-loader-indicator [loading]="(loading$ | async) ?? false"></app-loader-indicator>
</div>

<app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>
