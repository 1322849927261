<header class="header" [ngClass]="cssClass" (fstrInViewport)="inViewChange($event)" [once]="false" [threshold]="0.25">
  <figure class="header__crop" *ngIf="imageUrl || videoBg">
    <img *ngIf="imageUrl" [src]="imageUrl | resize: 1980" />
    <fstr-html5-video class="page-header__video" [playerVars]="playerVars" *ngIf="inviewPort && videoBg" [src]="videoBg"></fstr-html5-video>
  </figure>
  <div class="header__content text-center">
    <h1 class="header__title h1" [innerHTML]="title"></h1>
    <h2 class="header__subtitle h3" [innerHTML]="subtitle" *ngIf="subtitle"></h2>
  </div>
  <ng-content></ng-content>
</header>
